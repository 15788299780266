<template>
    <div>
        <styled-interface>
            <template #interface-heading>
                Reseller Onboarding
            </template>

            <div>
                <styled-card>
                    <template #heading>
                        Reseller Invites
                    </template>
                    <template #action-buttons>
                        <action-button
                            icon="add-permission"
                            @click="addResellerDialog = true">
                            Add Reseller
                        </action-button>
                        <action-button
                            icon="refresh"
                            class="ml-3"
                            :loading="loadingInvites"
                            @click="getInvites">
                            Refresh
                        </action-button>
                    </template>

                    <!--Table block-->
                    <div>
                        <styled-table
                            :headers="headers"
                            :items="invites"
                            :loading="loadingInvites"
                            sort-by="created_at"
                            hide-all-rows-option
                            has-search
                            has-filters>
                            <template
                                slot="items"
                                slot-scope="props">
                                <tr :class="(props.index % 2) ? 'row-odd' : ''">
                                    <td class="pa-4">
                                        <template v-if="props.item.name">
                                            {{ props.item.name }}
                                        </template>
                                    </td>
                                    <td class="pa-4">
                                        <template v-if="props.item.user_name">
                                            {{ props.item.user_name }}
                                        </template>
                                    </td>
                                    <td class="pa-4">
                                        <template v-if="props.item.created_at">
                                            {{ props.item.created_at.date | localizeTimezone | moment("MM/DD/YY") }}
                                        </template>
                                    </td>
                                    <td class="pa-4 text-center">
                                        <template v-if="props.item.uuid">
                                            {{ buildUrl(props.item) }}
                                        </template>
                                    </td>
                                    <td class="pa-4 text-center">
                                        <template v-if="props.item.uuid">
                                            <action-button
                                                v-clipboard:copy="buildUrl(props.item)"
                                                v-clipboard:success="onCopy"
                                                icon="copy" />
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </styled-table>
                    </div>
                </styled-card>

                <styled-slideout v-model="addResellerDialog">
                    <div class="pa-5">
                        <v-alert
                            :value="error"
                            type="error">
                            Error creating reseller invite. Please contact support.
                        </v-alert>
                        <h2>Invite Reseller</h2>
                        <p class="mb-4">
                            Create a reseller invite link for a potential new reseller. This link will allow them
                            to login with Facebook and set up a new reseller in BuyerBridge.
                        </p>
                        <v-text-field
                            v-model="name"
                            label="Reseller Name"
                            class="styled-field mb-4" />

                        <div class="text-xs-center mt-5">
                            <styled-button
                                :disabled="!name"
                                :loading="loadingCreateInvite"
                                @click="createInvite()">
                                Create
                            </styled-button>
                        </div>
                    </div>
                </styled-slideout>
            </div>
        </styled-interface>
    </div>
</template>

<script>
    import StyledInterface from '@/components/globals/StyledInterface';
    import StyledCard from '@/components/globals/StyledCard';
    import ActionButton from '@/components/globals/ActionButton';
    import StyledButton from '@/components/globals/StyledButton';
    import StyledSlideout from '@/components/globals/StyledSlideout';
    import StyledTable from '@/components/globals/StyledTable';
    import HTTP from '@/http';
    export default {
        components: {
            StyledInterface,
            StyledCard,
            ActionButton,
            StyledButton,
            StyledSlideout,
            StyledTable,
        },
        data() {
            return {
                name: '',
                addResellerDialog: false,
                loadingInvites: false,
                loadingCreateInvite: false,
                error: false,
                invites: [],
                headers: [
                    {
                        text: 'Name',
                        value: 'name',
                        align: 'left',
                        sortable: true,
                    },
                    {
                        text: 'Created By',
                        value: 'user_name',
                        align: 'left',
                        sortable: true,
                        filter: 'multiple'
                    },
                    {
                        text: 'Date Created',
                        value: 'created_at',
                        align: 'left',
                        sortable: true
                    },
                    {
                        text: 'Link',
                        value: 'uuid',
                        align: 'center',
                        sortable: false
                    },
                    {
                        text: 'Copy Link',
                        value: 'uuid',
                        align: 'center',
                        sortable: false
                    }
                ]
            };
        },
        created() {
            this.getInvites();
        },
        methods: {
            buildUrl(invite) {
                return window.location.origin + this.$router.resolve({ name: 'reseller-sign-up', query: { id: invite?.uuid } }).href;
            },
            async getInvites() {
                try {
                    this.invites = [];
                    this.loadingInvites = true;
                    const response = await (new HTTP).get('/agency-invites');
                    this.invites = response.data.map(invite => {
                        // Map the user name to a top level element for table filtering
                        invite.user_name = invite.user?.name ?? null;
                        return invite;
                    });
                } catch (error) {
                    console.log(error);
                } finally {
                    this.loadingInvites = false;
                }
            },
            async createInvite() {
                try {
                    this.error = false;
                    this.loadingCreateInvite = true;
                    await (new HTTP).post('/agency-invites', {
                        name: this.name,
                        configuration: {
                            fb_login: false
                        }
                    });
                    this.name = '';
                } catch (error) {
                    this.error = true;
                    console.log(error);
                } finally {
                    this.loadingCreateInvite = false;
                    this.addResellerDialog = false;
                    this.getInvites();
                }
            },
            onCopy() {
                this.$flash('Link copied to clipboard!', 'green');
            }
        }
    };
</script>
